/**
 * Detect if the current device has multi-touch.
 * This can (currently!) be used to distinguish Mac from iPad devices.
 *
 * @returns {boolean}
 */
const isMultitouch = () => {
  return !!navigator.maxTouchPoints && navigator.maxTouchPoints > 2
}

/**
 * @returns {[string]} List of classes describing the platform
 *                     and type of the client device.
 */
const getDeviceClasses = () => {
  const ua = window.navigator.userAgent.toLowerCase();

  if (/ipod|iphone/i.test(ua)) {
    return ['iphone', 'mobile'];
  }
  if (/android/i.test(ua)) {
    return ['android', 'mobile'];
  }
  if (/ipad/i.test(ua)) {
    return ['ipad', 'mobile'];
  }
  if (/macintosh/i.test(ua)) {
    // Could be mac or modern iPad
    if (isMultitouch()) {
      return ['ipad', 'desktop'];
    } else {
      return ['mac', 'desktop'];
    }
  }

  return [];
}

// Add css classes indicating the current platform to the root element
const classList = document.documentElement.classList;
classList.add.apply(
  classList,
  getDeviceClasses()
);
